import Immutable from 'seamless-immutable';

// Locals
import { license } from '~/api';
import i18n from '~/common/helpers/i18n';
import { csvDownload } from '~/common/helpers/jsonToCsv';

const initialState = Immutable({
  current: '',

  getRealm: null,
  license: null,
  usageList: null,
  pipelinesUsage: null,
  limitDetail: null,
  proposalUpsell: null,

  loadingLimitDetail: false,
  loadingPipelineUsage: false,
  loadingExport: false,
  loadingLicense: false,
  loadingLimitOfUse: false,
  loadingProposalUpsell: false,

  contacts: null,
  openContacts: false,

  loadingConsumptionModel: false,
  // consumptionModelData: null,
  consumptionModelData: {
    "granted": 1625.25,
    "spent": 125.78,
    "available": 2500.75,
    "overage": 0.0,
    "standard": 1250.20,
    "currency": "USD",
    "totalConsumptionPerMetrics": {
      "gbSeconds": 20.2,
      "executions": 300
    },
    "totalConsumptionPerEnvironment": {
      "prod": 20.2,
      "env": 18.2
    },
    "monthOnMonth": [
      {
        "month": "1",
        "value": {
          "prod": 20.2,
          "env": 18.2
        }
      },
      {
        "month": "2",
        "value": {
          "prod": 20.2,
          "env": 18.2
        }
      },
      {
        "month": "3",
        "value": {
          "prod": 20.2,
          "env": 18.2
        }
      },
    ],
    "consumptionByProjects": [
      {
        "project": "Default",
        "projectDescription": "015: interface",
        "totalPipelines": 20,
        "environments": {
          "prod": 20,
          "test": 6642,
          "homolog": 6642
        }
      },
      {
        "project": "Default",
        "projectDescription": "015: interface",
        "totalPipelines": 20,
        "environments": {
          "prod": 20,
          "test": 6642,
          "homolog": 6642
        }
      }
    ]
  },
});

const licenseModel = {
  name: 'license',
  state: initialState,
  reducers: {
    setEntity(state, realms) {
      return state.merge({ ...realms });
    },
    setRealm(state, realm) {
      return state.merge({ current: realm });
    },
    setGetRealm(state, realm) {
      return state.merge({ getRealm: realm });
    },
    setLicense(state, licenseData) {
      return state.merge({ license: licenseData });
    },
    setUsageList(state, usageList) {
      return state.merge({ usageList });
    },
    setLimitOfUse(state, limitOfUse) {
      return state.merge({ limitOfUse });
    },
    setPipelinesUsage(state, pipelinesUsage) {
      return state.merge({ pipelinesUsage });
    },
    setLimitDetail(state, limitDetail) {
      return state.merge({ limitDetail });
    },
    setProposalUpsell(state, proposalUpsell) {
      return state.merge({ proposalUpsell });
    },
    setLoadingPipelineUsage(state, loadingPipelineUsage) {
      return state.merge({ loadingPipelineUsage });
    },
    setLoadingExport(state, loadingExport) {
      return state.merge({ loadingExport });
    },
    setLoadingLicense(state, loadingLicense) {
      return state.merge({ loadingLicense });
    },
    setLoadingLimitOfUse(state, loadingLimitOfUse) {
      return state.merge({ loadingLimitOfUse });
    },
    setLoadingLimitDetail(state, loadingLimitDetail) {
      return state.merge({ loadingLimitDetail });
    },
    setLoadingConsumptionModel(state, loadingConsumptionModel) {
      return state.merge({ loadingConsumptionModel });
    },
    setContacts(state, contacts) {
      return state.merge({ contacts });
    },
    setLoadingProposalUpsell(state, loadingProposalUpsell) {
      return state.merge({ loadingProposalUpsell });
    },
    setOpenContacts(state, openContacts) {
      return state.merge({ openContacts });
    },
    setConsumptionModelData(state, consumptionModelData) {
      return state.merge({ consumptionModelData });
    }
  },
  effects: dispatch => ({
    async getLicense(params, state) {
      try {
        dispatch.license.setLoadingLicense(true);
        const { data } = await license.getLicense({
          realm: state.application.realm.realm,
          projectsInformation: params?.projectsInformation,
          environment: 'all'
        });
        dispatch.license.setLicense(data?.license);
        if (params?.projectsInformation) {
          dispatch.license.setUsageList(
            data?.license?.projectsInformation?.projects
          );
        }
      } catch (e) {
        dispatch.license.setLicense(null);
      } finally {
        dispatch.license.setLoadingLicense(false);
      }
    },
    async getLimitOfUse(params, state) {
      try {
        dispatch.license.setLoadingLimitOfUse(true);
        const { data } = await license.getLimitOfUse({
          realm: state.application.realm.realm,
          environment: params?.environment,
          isGlobal: params?.isGlobal,
          pipeline: params?.pipeline,
          status: params?.status
        });
        dispatch.license.setLimitOfUse(data?.limitOfUse);
        dispatch.license.setLoadingLimitOfUse(false);
      } catch (e) {
        dispatch.license.setLimitOfUse(null);
        dispatch.license.setLoadingLimitOfUse(false);
      }
    },
    async getPipelinesUsage(params, state) {
      try {
        dispatch.license.setLoadingPipelineUsage(true);
        const data = await license.getPipelinesUsage({
          realm: state.application.realm.realm,
          environment: params?.environment,
          project: params?.project
        });
        dispatch.license.setPipelinesUsage(data?.pipelinesUsage);
        dispatch.license.setLoadingPipelineUsage(false);
      } catch (e) {
        dispatch.license.setPipelinesUsage(null);
        dispatch.license.setLoadingPipelineUsage(false);
      }
    },
    async getLimitOfUseDetail(params, state) {
      try {
        dispatch.license.setLoadingLimitDetail(true);
        const data = await license.getLimitOfUseDetail({
          realm: state.application.realm.realm,
          environment: params?.environment,
          type: params?.type,
          dateFrom: params?.dateFrom,
          dateTo: params?.dateTo
        });
        dispatch.license.setLimitDetail(data?.limitOfUseDetail);
        dispatch.license.setLoadingLimitDetail(false);
      } catch (e) {
        dispatch.license.setLimitDetail(null);
        dispatch.license.setLoadingLimitDetail(false);
      }
    },
    async getProposalUpsell(params, state) {
      try {
        dispatch.license.setLoadingProposalUpsell(true);
        const data = await license.getProposalUpsell({
          realm: state.application.realm.realm,
          environment: params?.environment
        });
        dispatch.license.setProposalUpsell(data?.proposalUpsell);
        dispatch.license.setLoadingProposalUpsell(false);
      } catch (e) {
        dispatch.license.setProposalUpsell(null);
        dispatch.license.setLoadingProposalUpsell(false);
      }
    },
    async exportUsageList(params, state) {
      try {
        dispatch.license.setLoadingExport(true);
        const data = await license.getPipelinesUsage({
          realm: state.application.realm.realm,
          environment: params?.environment
        });
        dispatch.license.setPipelinesUsage(data?.pipelinesUsage);
        csvDownload({
          data: data?.pipelinesUsage?.deployments?.map(usage => ({
            project: usage?.projectName,
            projectDescription: usage?.projectDescription,
            concurrentExecution: usage?.concurrentExecution,
            pipelineVersion: usage?.pipelineVersion,
            environment: usage?.environment,
            pipeline: usage?.pipelineName,
            size: usage?.size,
            replicas: usage?.replica,
            rtu: usage?.rtu
          })),
          filename: `usage-list | ${new Date().toISOString().split('T')[0]}`,
          delimiter: ',',
          headers: params?.headers
        });
        dispatch.license.setLoadingExport(false);
        dispatch.snackbar.create({ text: i18n.t('label.download_done_msg_success') })
      } catch (e) {
        dispatch.license.setLoadingExport(false);
      }
    },
    async getContacts(_, state) {
      try {
        const { data } = await license.getRealmContacts(
          state.application.realm.realm
        );
        dispatch.license.setContacts(data?.realmContacts);
      } catch (e) {
        dispatch.license.setContacts(null);
      }
    }
  })
};

export default licenseModel;
